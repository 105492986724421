/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useMemo } from 'react';
import s from './s.module.less';
import BasicInfo from 'components/PracticeFrontFromComponents/BasicInfo';
import Insurance from 'components/PracticeFrontFromComponents/Insurance';
import ChannelPlatforms from 'components/PracticeFrontFromComponents/ChannelsPlatforms';
import AboutMe from 'components/PracticeFrontFromComponents/AboutMe';
import MyPhilosophy from 'components/PracticeFrontFromComponents/MyPhilosophy';
import SpecialtiesApproaches from 'components/PracticeFrontFromComponents/SpecialtiesApproaches';
import WorkExperience from 'components/PracticeFrontFromComponents/WorkExperience';
import Education from 'components/PracticeFrontFromComponents/Education';
import Service from 'components/PracticeFrontFromComponents/Service';
import EditStatusStore from 'store/PracticeFront/editStatus';
import { EditModuleEnum, IProviderStore } from 'types/practiceFront';
import MeetingTypes from 'components/PracticeFrontFromComponents/MeetingTypes';
import LocalSEO from 'components/PracticeFrontFromComponents/LocalSEO';
import { EmptyFields, HTML_DIV_ID } from 'constants/common';

interface IProps {
    showEditingTip?: boolean;
    providerData: IProviderStore;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    onSubmit: (formValue, field: EditModuleEnum, callback?: () => void) => void;
    refetch: () => void;
    scrollToTheEditingModule: () => void;
    setShowEditingTip: (status: boolean) => void;
    emptyFields: string[]
    // setTabKey: (key: string) => void;
}

const HomePage = ({
    showEditingTip,
    providerData,
    onEditStatusChange,
    onSubmit,
    refetch,
    scrollToTheEditingModule,
    setShowEditingTip,
    emptyFields,
    // setTabKey,
}: IProps) => {
    const [getEditStatus] = EditStatusStore.useStore();
    // const [getUser] = ProviderStore.useStore();
    const editStatusMap = getEditStatus('data') as Record<EditModuleEnum, boolean>;

    const { basicInfo, insurance, channels, aboutMe, myPhilosophy, specialtiesApproaches, experience, education, service, contact, seoData, containSeoApp } = providerData;

    const commomProps = useMemo(() => ({
        onEditStatusChange,
        showEditingTip,
        onSubmit,
        refetch,
        scrollToTheEditingModule,
        setShowEditingTip,
    }), [onEditStatusChange, onSubmit, refetch, showEditingTip, scrollToTheEditingModule]);

    return (
        <div className={s.wrap} id={HTML_DIV_ID.PF_HOME_WRAP}>
            {
                emptyFields.length > 0 && (
                    <div className={s.emptyFields}>
                        <div className={s.warningIcon} />
                        <div className={s.filedsList}>
                            <div className={s.fieldsTitle}>Complete the following sections for optimization</div>
                            <ul>
                                {
                                    emptyFields.map((e, i) => {
                                        return <li key={i} className={s.field}><a href={`#${e}`}>{e}</a></li>;
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                )
            }
            <div className={s.editItem}>
                <BasicInfo {...commomProps} initFormValue={basicInfo} isEditing={editStatusMap[EditModuleEnum.BASICINFO]} />
            </div>
            <div className={s.editItem}>
                <Insurance {...commomProps} initFormValue={insurance} isEditing={editStatusMap[EditModuleEnum.INSURANCE]} />
            </div>
            <div className={s.editItem}>
                <ChannelPlatforms initFormValue={channels} />
            </div>
            <div className={s.editItem}>
                <AboutMe providerId={basicInfo.id} {...commomProps} initFormValue={aboutMe} isEditing={editStatusMap[EditModuleEnum.ABOUTME]} />
            </div>
            <div className={s.editItem}>
                <div className={s.anchor} id={`${EmptyFields.MyPhilosophy}`} />
                <MyPhilosophy {...commomProps} initFormValue={myPhilosophy} isEditing={editStatusMap[EditModuleEnum.MYPHILOSOPHY]} />
            </div>
            <div className={s.editItem}>
                <div className={s.anchor} id={`${EmptyFields.SpecialtiesApproaches}`} />
                <SpecialtiesApproaches {...commomProps} initFormValue={specialtiesApproaches} isEditing={editStatusMap[EditModuleEnum.SEPCIALITIESAPPROACH]} />
            </div>
            <div className={s.editItem}>
                <div className={s.anchor} id={`${EmptyFields.WorkExperience}`} />
                <WorkExperience {...commomProps} initFormValue={experience} />
            </div>
            <div className={s.editItem}>
                <div className={s.anchor} id={`${EmptyFields.Education}`} />
                <Education {...commomProps} initFormValue={education} />
            </div>
            <div className={s.editItem}>
                <Service {...commomProps} addressList={contact?.addressList || []} initFormValue={service} />
            </div>
            <div className={s.editItem}>
                <MeetingTypes {...commomProps} initFormValue={service} contact={contact} />
            </div>
            <div className={s.editItem} id={HTML_DIV_ID.LOCAL_SEO_WRAP}>
                <LocalSEO {...commomProps} initFormValue={seoData} containSeoApp={containSeoApp} practiceAddress={contact?.addressList || []} uniProfile={providerData} isEditing={editStatusMap[EditModuleEnum.LOCAL_SEO]} />
            </div>

        </div>
    );
};

export default HomePage;
